import { Component, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IEntityService } from '../entities/entity.service';
import { ReferralCodeComponent } from './referral-code/referral-code.component';
import { PointsSummaryComponent } from './points-summary/points-summary.component';
import { UsersRestaurantsComponent } from './users-restaurants/users-restaurants.component';
import { UserTableSummaryComponent } from './user-table-summary/user-table-summary.component';
import { TablesUserSummayComponent } from './tables-user-summay/tables-user-summay.component';
import { VenuesTableSummaryComponent } from './venues-table-summary/venues-table-summary.component';

interface TabDefinition {
  title: string;
  component: Type<any>;
}

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {

  selectedTabIndex: number = 0;
  tabs: TabDefinition[] = [
    {
      title: 'Referral Code',
      component: ReferralCodeComponent
    },
    {
      title: 'Points Summary',
      component: PointsSummaryComponent
    },
    {
      title: 'Tables Summary',
      component: UsersRestaurantsComponent
    },
    {
      title: 'User - Table Summay',
      component: UserTableSummaryComponent
    },
    {
      title: 'Table - User Summay',
      component: TablesUserSummayComponent
    },
    {
      title: 'Venue - Tables Summay',
      component: VenuesTableSummaryComponent
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }
}
