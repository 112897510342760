import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { VenueService } from '../venue.service';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';

interface tablesInfo {
  id: string,
  name: string,
  period: string,
  timePeriodStart: string,
  timePeriodEnd: string,
  hosted: number,
  rsvps: number,
  attendees: number,
  cancelations: number,
  revenue: number,
}

export interface TableFilters {
  search: string;
  page: number;
  limit: number;
}

@Component({
  selector: 'app-venue-tables',
  templateUrl: './venue-tables.component.html',
  styleUrls: ['./venue-tables.component.scss']
})
export class VenueTablesComponent implements OnInit {

  tablesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tablesInfo: tablesInfo[] = [];
  filters: TableFilters = {
    search: null,
    page: 1,
    limit: 20
  };
  totalCount = 0;
  totalPages = 0;
  private searchSubject = new Subject<string>();

  constructor(
      private venueService: VenueService,
      private router: Router,
      private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    this.filters.search = venueId;
    try {
      this.loadData();
    } catch (error) {
      console.error(error);
    }
  }

  async loadData() {
    this.tablesReady.next(false);
    try {
      const result = await await this.venueService.getVenueTablesBasicInfo(this.filters.page, this.filters.limit, this.filters.search);
      this.tablesInfo = result.data;
      this.totalCount = result.total;
      this.totalPages = Math.round(this.totalCount / this.filters.limit);
      if (this.totalPages < 1) {
        this.totalPages = 1;
      }
    } catch (error) {
      console.error('Error loading events:', error);
    } finally {
      this.tablesReady.next(true);
    }
  }

  formatTimePeriod(period: string, timePeriodStart: string): string {
    const nyTimezone = 'America/New_York';
   
    switch (period) {
      case 'daily':
        return moment.tz(timePeriodStart, nyTimezone).format('YYYY-MM-DD');
      case 'weekly':
        const weekNumber = moment.tz(timePeriodStart, nyTimezone).isoWeek();
        return `Week ${weekNumber} ${moment.tz(timePeriodStart, nyTimezone).format('YYYY')}`;
      case 'monthly':
        return moment.tz(timePeriodStart, nyTimezone).format('MMMM YYYY');
      case 'annual':
        return moment.tz(timePeriodStart, nyTimezone).format('YYYY');
      default:
        return 'Unknown';
    }
  }

  onTablesClick(id: string): void {
    if (id) {
      this.router.navigate(['/' + 'events' + '/' + id]);
    }
  }

}
