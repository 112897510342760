import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDataComponent } from './user-data.component';
import { UserDataService } from './user-data.service';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { UserRoles } from '../users/user.roles';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { UserTablesComponent } from '../users/tables/tables.component';
import { ReferralCodeComponent } from './referral-code/referral-code.component';
import { PointsSummaryComponent } from './points-summary/points-summary.component';
import { UsersRestaurantsComponent } from './users-restaurants/users-restaurants.component';
import { UserTableSummaryComponent } from './user-table-summary/user-table-summary.component';
import { TablesUserSummayComponent } from './tables-user-summay/tables-user-summay.component';
import { VenuesTableSummaryComponent } from './venues-table-summary/venues-table-summary.component';

@NgModule({
  declarations: [UserDataComponent, ReferralCodeComponent, PointsSummaryComponent, PointsSummaryComponent, UsersRestaurantsComponent, UserTableSummaryComponent, TablesUserSummayComponent, VenuesTableSummaryComponent],
  providers: [UserDataService],
  entryComponents: [ReferralCodeComponent, PointsSummaryComponent, UsersRestaurantsComponent, UserTableSummaryComponent, TablesUserSummayComponent, VenuesTableSummaryComponent],
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    FormsModule,
    RouterModule.forChild([{
      path: 'user-data',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/user-data',
          icon: 'glyphicon-stats',
          label: 'User Data',
          category: 'user data',
          index: 6
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: UserDataComponent,
          canActivate: [AuthenticatedGuard],
          data: {
            title: 'User Data',
            category: 'data',
          },
        },
      ]
    }])
  ],
})
export class UserDataModule { }
