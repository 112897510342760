import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../user-data.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { TableFilters } from '../user-table-summary/user-table-summary.component';

interface referralInfo {
  id: string,
  name: string,
  email: string,
  referraldCode: string,
  totalSpend: number,
  eventsJoined: number,
  eventsHosted: number,
  eventsNoShow: number,
}

@Component({
  selector: 'app-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrls: ['./referral-code.component.scss']
})
export class ReferralCodeComponent implements OnInit {

  refInfo: referralInfo[] = [];
  tablesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchAll: string = '';
  filteredUsers: any[];
  filters: TableFilters = {
    search: null,
    page: 1,
    limit: 20
  };
  totalCount = 0;
  totalPages = 0;
  private searchSubject = new Subject<string>();

  constructor(
    private dataService: UserDataService,
  ) { }

  async ngOnInit() {
    try {
      this.loadData();
    } catch (error) {
      console.error(error);
    }
  }

  onSearchChange(event: any) {
    const searchTerm = event.target.value;
    this.filters.search = searchTerm;
    this.filters.page = 1;
    this.searchSubject.next(searchTerm);
    this.loadData();
  }

  onPageChange(page: number) {
    if (page < 1) return;
    if (page >= this.totalPages) return;
    this.filters.page = page;
    this.loadData();
  }

  async loadData() {
    this.tablesReady.next(false);
    try {
      const result = await await this.dataService.getUserTablesRecap(this.filters.page, this.filters.limit, this.filters.search);
      this.refInfo = result.data;
      this.totalCount = result.total;
      this.totalPages = Math.round(this.totalCount / this.filters.limit);
      if (this.totalPages < 1) {
        this.totalPages = 1;
      }
    } catch (error) {
      console.error('Error loading events:', error);
    } finally {
      this.tablesReady.next(true);
    }
  }

  async filterAllUsers() {
    this.filteredUsers = this.refInfo.filter(item => new RegExp(this.searchAll, 'i').test(item.email));
  }

}
