import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TableFilters } from '../user-table-summary/user-table-summary.component';
import { UserDataService } from '../user-data.service';
import { Router } from '@angular/router';

interface tablesInfo {
  id: string,
  email: string,
  events: [],
  tableName: string,
  venueName: string,
  eventId: string,
  userTransactions: [],
  paymentMethodUsed: string,
  attendeed: string,
  totalRevenue: number,
  isOwner: string,
  visitDate: string,
  RSVPMethod: string,
}

@Component({
  selector: 'app-tables-user-summay',
  templateUrl: './tables-user-summay.component.html',
  styleUrls: ['./tables-user-summay.component.scss']
})
export class TablesUserSummayComponent implements OnInit {

  tablesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tablesInfo: tablesInfo[] = [];
  filters: TableFilters = {
    search: null,
    page: 1,
    limit: 20
  };
  totalCount = 0;
  totalPages = 0;
  private searchSubject = new Subject<string>();

  constructor(
      private dataService: UserDataService,
      private router: Router,
  ) { }

  async ngOnInit() {
    try {
      this.loadData();
    } catch (error) {
      console.error(error);
    }
  }

  onSearchChange(event: any) {
    const searchTerm = event.target.value;
    this.filters.search = searchTerm;
    this.filters.page = 1;
    this.searchSubject.next(searchTerm);
    this.loadData();
  }

  onPageChange(page: number) {
    if (page < 1) return;
    if (page >= this.totalPages) return;
    this.filters.page = page;
    this.loadData();
  }

  async loadData() {
    this.tablesReady.next(false);
    try {
      const result = await await this.dataService.getTablesUserBasicInfo(this.filters.page, this.filters.limit, this.filters.search);
      this.tablesInfo = result.data;
      this.totalCount = result.total;
      this.totalPages = Math.round(this.totalCount / this.filters.limit);
      if (this.totalPages < 1) {
        this.totalPages = 1;
      }
    } catch (error) {
      console.error('Error loading events:', error);
    } finally {
      this.tablesReady.next(true);
    }
  }

  onEmailClick(id: string): void {
    if (id) {
      this.router.navigate(['/' + 'users' + '/' + id]);
    }
  }

  onTableClick(id: string): void {
    if (id) {
      this.router.navigate(['/' + 'events' + '/' + id]);
    }
  }

}
