import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VenueService } from './venue.service';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { VenueServiceResolver } from './venue.service.resolver';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { VenueLocationComponent } from './venue-location/venue-location.component';
import { VenueWorkingHoursComponent } from './venue-working-hours/venue-working-hours.component';
import { SharedModule } from '../shared/shared.module';
import { VenueEventsComponent } from './venue-events/venue-events.component';
import { MomentModule } from 'angular2-moment';
import { VenueGalleryComponent } from './venue-gallery/venue-gallery.component';
import { VenueVouchersComponent } from './venue-vouchers/venue-vouchers.component';
import { VenueOwnerComponent } from './venue-owner/venue-owner.component';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { HappyHourModule } from '../happy-hour/happy-hour.module';
import { MenuCategoryCopyComponent } from './venue-menu/menu-category.copy.component';
import { MenuCategoryEditComponent } from './venue-menu/menu-category.edit.component';
import { MenuItemCreateComponent } from './venue-menu/menu-item.create.component';
import { MenuItemEditComponent } from './venue-menu/menu-item.edit.component';
import { VenueMenuComponent } from './venue-menu/venue-menu.component';
import { VenueTableNamesComponent } from './venue-table-names/venue-table-names.component';
import { MenuService } from './venue-menu/venue-menu.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VenueAuditsComponent } from './venue-audit/venue-audits.component';
import { EntitiesModule } from '../entities/entities.module';
import { WaitersListComponent } from './venue-waiters/waiters-list.component';
import { WaiterCreateComponent } from './venue-waiters/waiter-create.component';
import { UserRoles } from '../users/user.roles';
import { VenueSettingsComponent } from './venue-settings/venue-settings.component';
import { VenuePostsComponent } from './venue-posts/venue-posts.component';
import { VenuePostCreateComponent } from './venue-posts/venue-post-create.component';
import { VenueChargesComponent } from './venue-charges/venue-charges.component';
import { CreateVenueChargeComponent } from './venue-charges/create-venue-charge.component';
import { VenueTablesComponent } from './venue-tables/venue-tables.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MomentModule,
    LaddaModule,
    GenericFormModule,
    FileUploadModule,
    HappyHourModule,
    MultiSelectModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    NgxIntlTelInputModule,
    TimepickerModule,
    DataTablesModule,
    EntitiesModule,
    UiSwitchModule.forRoot({
      size: 'medium'
    }),
    DragDropModule,
    RouterModule.forChild([{
      path: 'venues',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/venues',
          icon: 'glyphicon glyphicon-cutlery',
          label: 'Restaurants',
          category: 'venues',
          index: 3
        },
        access: [UserRoles.ADMIN, UserRoles.VENDOR]
      },
      children: [
        {
          path: '',
          component: EntityListComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: VenueServiceResolver },
          data: { category: 'venues', title: 'Restaurants', disableCreateEntity: true }
        },
        {
          path: ':id',
          component: EntityEditComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: VenueServiceResolver },
          data: {
            category: 'venues',
            title: 'Venue Info',
            tabs: [{
              path: 'settings',
              label: 'Settings'
            }, {
              path: 'location',
              label: 'Location'
            }, {
              path: 'working-hours',
              label: 'Working Hours'
            }, {
              path: 'events',
              label: 'Reservations'
            }, {
              path: 'gallery',
              label: 'Gallery'
            }, {
              path: 'vouchers',
              label: 'Vouchers'
            }, {
              path: 'owner',
              label: 'Owner'
            }, {
              path: 'menu',
              label: 'Menu'
            }, {
              path: 'table-names',
              label: 'Table Names'
            }, {
              path: 'waiters',
              label: 'Waiters'
            }, {
              path: 'audit',
              label: 'Audit'
            }, {
              path: "posts",
              label: "Posts"
            }, {
              path: "charges",
              label: "Charges"
            }, {
              path: "tables",
              label: "Tables"
            }
            ]
          },
          children: [{
            path: 'settings',
            component: VenueSettingsComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Settings' }
          }, {
            path: 'location',
            component: VenueLocationComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Location' }
          }, {
            path: 'working-hours',
            component: VenueWorkingHoursComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Working Hours' }
          }, {
            path: 'events',
            component: VenueEventsComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Reservations' }
          }, {
            path: 'gallery',
            component: VenueGalleryComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Gallery' }
          }, {
            path: 'vouchers',
            component: VenueVouchersComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', titile: 'Restaurant Vouchers' }
          }, {
            path: 'owner',
            component: VenueOwnerComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', titile: 'Restaurant Owner' }
          }, {
            path: 'menu',
            component: VenueMenuComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', titile: 'Restaurant Menu' }
          }, {
            path: 'table-names',
            component: VenueTableNamesComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Table Names' }
          }, {
            path: 'waiters',
            component: WaitersListComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Restaurant Servers' }
          }, {
            path: 'audit',
            component: VenueAuditsComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Venue Audits' }
          }, {
            path: 'posts',
            component: VenuePostsComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Venue Posts' }
          }, {
            path: 'charges',
            component: VenueChargesComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Venue Charges' }
          }, {
            path: 'tables',
            component: VenueTablesComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'venues', title: 'Venue Tables' }
          }
        ]
        }
      ]
    }])
  ],
  declarations: [
    VenueLocationComponent,
    VenueWorkingHoursComponent,
    VenueEventsComponent,
    VenueGalleryComponent,
    VenueVouchersComponent,
    VenueOwnerComponent,
    MenuCategoryCopyComponent,
    MenuCategoryEditComponent,
    MenuItemCreateComponent,
    MenuItemEditComponent,
    VenueMenuComponent,
    VenueTableNamesComponent,
    VenueAuditsComponent,
    WaitersListComponent,
    WaiterCreateComponent,
    VenueSettingsComponent,
    VenuePostsComponent,
    VenuePostCreateComponent,
    VenueChargesComponent,
    CreateVenueChargeComponent,
    VenueTablesComponent
  ],
  entryComponents: [
    MenuCategoryCopyComponent,
    MenuCategoryEditComponent,
    MenuItemCreateComponent,
    MenuItemEditComponent,
    WaiterCreateComponent,
    VenuePostCreateComponent,
    CreateVenueChargeComponent
  ],
  providers: [VenueService, VenueServiceResolver, MenuService]
})
export class VenueModule { }
